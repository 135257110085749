import React from 'react';

class PageNotFound extends React.Component{ 
 
  render(){
    return(
      <div>
        <img src="https://cdn-images-1.medium.com/max/1200/1*wcdgdC4ACqyq8bxmgaHh3w.png" width="100%"/>
      </div>
    );
  }
}
export default PageNotFound;
