import React, {Component} 	from 'react';
import { Link, withRouter,  Prompt } 			from 'react-router-dom';
import Webcam 				from 'react-webcam';
import $ 					from "jquery";
import axios 				from 'axios';
import moment 				from 'moment';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MCQ.css';

declare var jQuery: any;


class MCQ extends (Component)  {
	constructor(props){
		super(props);
		this.state={
			MCQdata  : this.props.questions,
			answers  : this.props.questions.answerArray,
			examType : this.props.examType,
			purchasepkg : this.props.purchasepkg,
			dataSaved : false,
			examTime : '00:00'
		}
		// console.log("examtype", this.props.examType);
		// console.log("ok",this.props.questions.lastVisitedQuestion);
	}

	
	componentDidMount(){	
		
	}	

	componentWillMount(){
		
		this.getTimefunction(this.state.MCQdata.examTime?this.state.MCQdata.examTime:localStorage.getItem("setInterval"),this.state.MCQdata._id);
		
	}
	

	getAnswer(event){
		event.preventDefault();
		var checkedStatus = event.target.checked;
		var index  		  = event.target.getAttribute('data-qNum');
		var studAnswer    = event.target.getAttribute('data-value');
		// var correctAnswer = event.target.getAttribute('data-right');
		var examTime 	  = $('.countdownWrap').text();
		var examId  	  = this.state.MCQdata._id;
		var nextQues	  = parseInt(index) + 1;
		/*if(studAnswer == correctAnswer){
			var answer = "Correct";
		}else{
			var answer = "Wrong";			
		}*/

		var formValues = {
			"examId"     	: examId,
			"questionNumber": index,
			"studentAnswer" : studAnswer,
			"examTime"   	: examTime,
			/*"answer"     	: answer,*/
		};

		let answers = [...this.state.answers];
		var action = 'insert';
		var i=0;
		for(i=0; i< answers.length; i++){
			if(answers[i].questionNumber == index){
				answers[i] = formValues ;
				action = 'update'; 
				// console.log("action ", i, answers[i]);
			}			
		}
		if(i == answers.length){
			if(action == 'insert'){
				answers.push( formValues );
			}
		
			this.setState({ answers, examTime : examTime });

		}
		
		if("#optionA"+"-"+index !== "#option"+studAnswer+"-"+index){			
			// $("#optionA"+"-"+index).prop('checked', false);
			$("#optionA"+"-"+index).removeAttr('checked');
			
		}
		if("#optionB"+"-"+index !== "#option"+studAnswer+"-"+index){			
			// $("#optionB"+"-"+index).prop('checked', false);
			$("#optionB"+"-"+index).removeAttr('checked');
			
		}
		if("#optionC"+"-"+index !== "#option"+studAnswer+"-"+index){			
			// $("#optionC"+"-"+index).prop('checked', false);
			$("#optionC"+"-"+index).removeAttr('checked');
			
		}
		if("#optionD"+"-"+index !== "#option"+studAnswer+"-"+index){			
			// $("#optionD"+"-"+index).prop('checked', false);
			$("#optionD"+"-"+index).removeAttr('checked');
			
		}
		

		

		// console.log("this.state.qIndex p exam",this.state.qIndex);
		// $('#ans'+(index)).hide();	
		$("#checkA"+"-"+index).removeClass("checkedanswer");	
		$("#checkB"+"-"+index).removeClass("checkedanswer");	
		$("#checkC"+"-"+index).removeClass("checkedanswer");	
		$("#checkD"+"-"+index).removeClass("checkedanswer");	
		
		$("#option"+studAnswer+"-"+index).prop('checked', true);

		$("#check"+studAnswer+"-"+index).addClass("checkedanswer");	
		
		
		this.fillcolorwhenanswer(index,studAnswer);

		setTimeout(()=>{
			 jQuery('#exam').carousel(nextQues);
		}, 250);
		// console.log("fgdhfhgsf",".option"+studAnswer+"-"+index);
		
	}

	fillcolorwhenanswer(getqNum,studAnswer){
		$('#mcqno'+getqNum).addClass("greenClor");
	}

	getTimefunction(examTime,id){
		// console.log("ontimer", examTime, id);
		if(examTime && id){
			clearInterval(this.interval);
			 // console.log('examTime = ',examTime,'id = ',id);
			this.interval = setInterval(()=>{
			  var timer = examTime.split(':');
			  var minutes = parseInt(timer[0], 10);
			  var seconds = parseInt(timer[1], 10);
			  --seconds;
			  minutes = (seconds < 0) ? --minutes : minutes;
			  if (minutes < 0){
			  	this.setState({ examTime : '00:00' });
			  	clearInterval(this.interval);
			    this.handleFinish();
			  }else{
				  seconds = (seconds < 0) ? 59 : seconds;
				  seconds = (seconds < 10) ? '0' + seconds : seconds;
				  minutes = (minutes < 10) ?  minutes : minutes;
				  $('.countdown').html(minutes + ':' + seconds);
				  examTime = minutes + ':' + seconds;
				}
			// console.log('Time = ',minutes + ':' + seconds);
			localStorage.setItem("setInterval",examTime);
			// console.log("local = ",localStorage.getItem("setInterval"))
			}, 1000);
		}else{
			$('.countdown').html("No Internet");
		}

	}

	handleFinish(){
		var examTime 	  = $('.countdownWrap').text();
		// console.log("Answers", this.state.answers);
		var formValues = {
			answers 	: this.state.answers,
			examId  	: this.state.MCQdata._id,
			examTime 	: examTime
		}
		if(this.state.examType === 'mainExam'){
			axios
			.post('/myexammasters/mainfinishexamend/submit',formValues)
			.then((response)=>{
				localStorage.removeItem("setInterval");
				clearInterval(this.interval);
				this.setState({dataSaved: true})
				this.props.history.push("/mainExamResult/"+formValues.examId)
			})
			.catch(function(error){
				console.log("Error while selected answer = ", error)
			})	
		}
		else{
			axios
              .post('/packagequestionpapermaster', this.state.purchasepkg)
              .then((response)=> {                        
                  var responseData = response.data;               
              })
              .catch(function (error) {
                  console.log(error);
                
              })

			axios
			.post('/mypracticeexammasters/finishexamend/submit',formValues)
			.then((response)=>{
				localStorage.removeItem("setInterval");
				clearInterval(this.interval);
				this.setState({dataSaved: true})
				this.props.history.push("/practiceExamResult/"+formValues.examId)
			})
			.catch(function(error){
				console.log("Error while selected answer = ", error)
			})	
			
		}
		
	}

	handleFinishonleave(){
		// alert("ok");
		var examTime 	  = $('.countdownWrap').text();
		// console.log("Answers =>handle leave", this.state.answers);
		var formValues = {
			answers 	: this.state.answers,
			examId  	: this.state.MCQdata._id,
			examTime 	: examTime
		}
		if(this.state.examType === "mainExam"){
			axios
			.post('/myexammasters/mainexamonleaveexam/submit',formValues)
			.then((response)=>{
				// localStorage.removeItem("setInterval");
				// clearInterval(this.interval);
				// this.props.history.push("/practiceExamResult/"+formValues.examId)
			})
			.catch(function(error){
				console.log("Error while selected answer = ", error)
			})	
		}
		else{
			axios
			.post('/mypracticeexammasters/onleaveexam/submit',formValues)
			.then((response)=>{
				// localStorage.removeItem("setInterval");
				// clearInterval(this.interval);
				// this.props.history.push("/practiceExamResult/"+formValues.examId)
			})
			.catch(function(error){
				console.log("Error while selected answer = ", error)
			})	
		}
		
	}

	componentWillUnmount(){
		clearInterval(this.interval);
	}

	
	render(){
		// console.log("this.state.MCQdata", this.state.MCQdata);
		
			return(
				<div className="mainmcqall content-wrapper content-wrapperexampaper  ">
				{/*<Prompt
			      when={!this.state.dataSaved}
			      message={this.handleFinishonleave.bind(this)}
			    />*/}
			        <section className="content-header1"></section>
			        <div className="col-lg-2 col-md-2 hidden-sm hidden-xs pull-right webCamStyle">
			          {this.state.examType === 'mainExam'?
			          <div className="innerWebCam">
			            <div className="drawSquare pull-right"></div>
			            <Webcam className="videoSizeDisplay pull-right" autoPlay="true"
			              audio={false}
			              height={100}
			              width={100}
			              ref={node => this.webcam = node}
			            />
			            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 recordWrap">&nbsp;&nbsp;&nbsp;Recording... </div>
			            </div>
			            :
			            null
			        }
			        </div>
			        <section className="content viewContent">
			            <div className="row">
			                <div className={this.state.examType === "mainExam" ? "col-lg-12 col-md-12 col-xs-12 col-sm-12 mcqsection mainexammcqsection" : "col-lg-12 col-md-12 col-xs-12 col-sm-12 mcqsection" }>
			                	<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 colpadding">
									<div className="col-lg-12 col-md-12 examDetailsWrap marginAuto">
									    
										<div className="col-lg-3 col-md-3 col-sm-3 examDetailsWrap1 makespancenter">{this.state.MCQdata.examName}</div>
										<div className="col-lg-3 col-md-3 col-sm-3 examDetailsWrap2 makespancenter">Total Questions : {this.state.MCQdata.totalQuestion}</div>
										<div className="col-lg-3 col-md-3 col-sm-3 examDetailsWrap3 makespancenter">Total Marks :  {this.state.MCQdata.totalMarks}</div>
										<div className="col-lg-1 col-md-1 col-sm-1 countdownWrapDiv makespancenter">
											<span className={this.state.examType === 'mainExam' ? "countdown countdownWrap makespancentercounter mainexamcountcss": "countdown countdownWrap makespancentercounter"}></span>
											<div className="hidden-lg hidden-md col-sm-6 col-xs-6 pull-right webCamStyle">
									          {this.state.examType === 'mainExam'?
									          <div className="innerWebCam">
									            <div className="drawSquare pull-right"></div>
									            <Webcam className="videoSizeDisplay pull-right" autoPlay="true"
									              audio={false}
									              height={100}
									              width={100}
									              ref={node => this.webcam = node}
									            />
									            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 recordWrap">&nbsp;&nbsp;&nbsp;Recording... </div>
									            </div>
									            :
									            null
									        	}
									        </div>
										</div>
									</div>		
								</div>
			                	<div className="CountIncrement">0</div>
			                	<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 colpadding paddingTopBoard">
									<div className="col-lg-2 col-md-2 hidden-sm hidden-xs colpadding paddingTop">
										<img src="/images/leftboy.png" className="examstdHeight"/>
									</div>
									<div id="exam" className="col-lg-8 col-md-8 col-sm-12 col-xs-12 carousel slide exambox noPadding" data-ride="carousel" data-interval="false">

									  
										  <ul className="carousel-indicators questionnumber">

										  	{this.state.MCQdata.answerArray.map((elem,index)=>{
										  		var liclass = "";

										  		if(this.props.questions.lastVisitedQuestion){
										  			if(parseInt(this.props.questions.lastVisitedQuestion) + 1 === index){
										  				liclass = 'active'
										  			}										  		
										  		}										  		
										  		if(elem.studentAnswer){
										  			liclass = 'greenClor';
										  		}
										  		if(!this.props.questions.lastVisitedQuestion){
											  		if(index === 0){
											  			liclass = 'active';
											  		}	
										  		}								  		
										  		
										  		
										  		return(
											    	<li data-target="#exam" data-slide-to={index} className={liclass} id={"mcqno"+index} >{index+1}</li>
											    )
										  	})}

										  	<li data-target="#exam" data-slide-to={this.state.MCQdata.answerArray.length} className="finishexamlibtn" >Finish</li>
										  </ul>

										 
										  <div className="carousel-inner col-lg-12 col-md-12 col-sm-12 col-xs-12 qusetionanssection noPadding">
										  	{this.state.MCQdata.answerArray.map((elem,index)=>{
										  		var itemclass = 'item';
										  		// console.log("dde",parseInt(this.props.questions.lastVisitedQuestion), index);
										  		if(this.props.questions.lastVisitedQuestion){
										  			if( parseInt(this.props.questions.lastVisitedQuestion) + 1 === index){
											  			itemclass = 'item active';
										  			}
										  		}										  		
										  		else if(index === 0){
										  			itemclass = 'item active';
										  		}
										  		
										  		if(elem.question){
										  			var questionArray = [];
										  			var questions = elem.question.split('');
										  			
														for(let i=0; i< questions.length; i++){
															if((questions[i]%1)===0) {
																questionArray.push(
																	<span className="quesDig">{questions[i]} </span>
																);
															}	
															else{
																questionArray.push(
																	<span className="arithmeticOpe"> {questions[i]} <br /></span>
																);
															}	
														}	
													}																							
										  		

										  		
										  		return(
										  			<div className={itemclass} id={"ans"+index}>
										  				
										  					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
											  					<div className="col-lg-6 col-lg-offset-2 col-md-6 col-md-offset-2 col-sm-6 col-sm-offset-2 col-md-6 col-xs-9 questionTitWrapp">Question No. <span className="questionTitsubWrap quesnumber">{index+1} :</span> </div>
																<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 questionTitsubWrap">{questionArray}</div>
															</div>		
														    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  questionAnsWrapp2">
														      	Answers : 
														    </div>
														      	
												      		<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 answerinputopions">
														      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 answerBottom"> 
														      		<label className="col-lg-8 col-md-8 col-sm-8 col-xs-8 containerr">
														      			<input type="radio" name={"answeropts"+index} id={"optionA-"+index} data-value="A" onChange={this.getAnswer.bind(this)}	data-qNum={elem.questionNumber}   />  
														      			<span className={elem.studentAnswer === 'A' ? "checkmarkk checkedanswer" : "checkmarkk" } id={"checkA-"+index} ><span className="checknewicon"></span></span>
														      			<span className="answeroption">{elem.A}</span>
														      		</label>	
														      </div>
														      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 answerBottom"> 
														      		<label className="col-lg-8 col-md-8 col-sm-8 col-xs-8 containerr">
														      			<input type="radio" name={"answeropts"+index} data-value="B" id={"optionB-"+index} onChange={this.getAnswer.bind(this)}	data-qNum={elem.questionNumber}  />  
														      			<span className={elem.studentAnswer === 'B' ? "checkmarkk checkedanswer" : "checkmarkk" } id={"checkB-"+index} ><span className="checknewicon"></span></span>
														      			<span className="answeroption">{elem.B}</span>
														      		</label>		
														      </div>
														      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 answerBottom"> 
														      		<label className="col-lg-8 col-md-8 col-sm-8 col-xs-8 containerr">
														      			<input type="radio" name={"answeropts"+index} data-value="C" id={"optionC-"+index} onChange={this.getAnswer.bind(this)}	data-qNum={elem.questionNumber}   />  
														      			<span className={elem.studentAnswer === 'C' ? "checkmarkk checkedanswer" : "checkmarkk" } id={"checkC-"+index} ><span className="checknewicon"></span></span>
														      			<span className="answeroption">{elem.C}</span>
														      		</label>	
														      </div>
														      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 answerBottom"> 
														      		<label className="col-lg-8 col-md-8 col-sm-8 col-xs-8 containerr">
														      			<input type="radio" name={"answeropts"+index} data-value="D" id={"optionD-"+index} onChange={this.getAnswer.bind(this)}	data-qNum={elem.questionNumber}  />  
														      			<span className={elem.studentAnswer === 'D' ? "checkmarkk checkedanswer" : "checkmarkk" } id={"checkD-"+index} ><span className="checknewicon"></span></span>
														      			<span className="answeroption">{elem.D}</span>
														      		</label>	
														      </div>
													    </div>  
													    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 addspaceitem" ></div>
														   
												    </div>
											    )
										  	})}

										  	<div className="item " id="finishexam">
										  		<div className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12 sliderTitles finishSlideWrap">
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
													      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 finishText">
													      	You are about to finish the Test.
													      </div>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
													      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 answerBottom finishsubText">
													      	Please click on below button to finish the Test.
													      </div>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 finishBttn">
															<button className="showNextWindowButtow btn btn-primary" onClick={this.handleFinish.bind(this)}>Finish exam</button>
														</div>
													</div>
												</div>
										  	</div>

										  </div>

										  
										  <a className="carousel-control-prev" href="#exam" data-slide="prev">
										    <span className="carousel-control-prev-icon glyphicon glyphicon-chevron-left"></span>
										  </a>
										  <a className="carousel-control-next" href="#exam" data-slide="next">
										    <span className="carousel-control-next-icon glyphicon glyphicon-chevron-right"></span>
										  </a>
										</div>
										
										<div className="col-lg-2 col-md-2 hidden-sm hidden-xs colpadding paddingTop">
											<img src="/images/rightgal.png" className="examstdHeight"/>
										</div>  
									</div>
							</div>	
						</div>
					</section>					
				</div>
			);	
	}
} 
export default withRouter(MCQ);
