import React, {Component} 	from 'react';
import Webcam 				from 'react-webcam';
import axios 				from 'axios';
import swal 				from 'sweetalert';
import $ 					from 'jquery';


import MCQ from '../../MCQ/MCQ.js';
import CompetitionDetailsforPayment from '../../../components/myAccount/components/CompetitionDetailsforPayment.js';

class Webcampage extends (Component)  {

	constructor(props){
		super(props);
		this.state={			
			webcamera           : false
		}
		
	}

		
	componentDidMount(){
		var user_ID = localStorage.getItem("user_ID");
		var competitionId = this.props.match.params.compId;
		var self = this;
		if ( navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		  navigator.mediaDevices.getUserMedia({ video: { width: 640 } })
		    .then(function (stream) {
		      self.setState({"webcamera" : true })
		      // alert("support");
		    })
		    .catch(function (error) {
		      console.log("Webcamera Not enable", error);
		      // alert("not suppoert");
		      self.setState({"webcamera" : false });
		    });
		}
		else{
			console.log("media device not supported");
		}
		/*if (navigator.webkitGetUserMedia) {
		    navigator.webkitGetUserMedia({video: true}, function() {
				console.log("Acquired audio and video!");
			},function() {
		    	console.log('err');
		    	// swal("Camera is blocked","Main exam requires access to your camera. Click the camera blocked icon  in your browser's address bar.","warning");
			})
		    
		} else {
		    alert("WebRTC not supported on this browser");
		}*/
		/*navigator.getMedia = ( 
			// navigator.getUserMedia || // use the proper vendor prefix
	        navigator.webkitGetUserMedia ||
	        navigator.mozGetUserMedia ||
	        navigator.msGetUserMedia);
			navigator.getMedia({video: true}, function() {
				self.setState({"webcamera" : true })
			},function() {
		    	self.setState({"webcamera" : false });
		    	// swal("Camera is blocked","Main exam requires access to your camera. Click the camera blocked icon  in your browser's address bar.","warning");
			})*/
		
	}
	

	startExam(event){
		
			event.preventDefault();
			$('.startExamBtn').css('display','none');
			$('.wrProcessing').css('display','block');
			const studentId = localStorage.getItem("user_ID");
			var compId = this.props.match.params.compId;
			var self = this;
			if ( navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		  	navigator.mediaDevices.getUserMedia({ video: { width: 640 } })
		    .then(function (stream) {
		      	axios
			        .post('/startexamcategorywise/mainexambycategory/'+compId+'/'+studentId)
			        .then((response)=>{						
						// this.props.history.push('/startExam/'+compId+'/'+response.data.ID);
						// this.props.history.push('/startExam/'+compId+'/'+response.data.record._id);
						var MCQdata = response.data.record;
						// console.log("MCQdata", MCQdata);
						self.setState({MCQdata: MCQdata});
			        })
			        .catch(function(error){			          	
			          	$('.startExamBtn').css('display','Block');
						$('.wrProcessing').css('display','none');
			        })
		    })
		    .catch(function (error) {
		      	$('.startExamBtn').css('display','Block');
 				$('.wrProcessing').css('display','none');
 			    swal({title : "As per company's rule, Student will be not allowed to attempt the final exam without camera",
 			    		text: "",
 			    		icon: "warning"})
		 			    .then((will) => {
		           window.location.reload(false);
		        });
		    });
		}
 	/*		navigator.getMedia = (
// 			// navigator.getUserMedia || // use the proper vendor prefix
 	        navigator.webkitGetUserMedia ||
 	        navigator.mozGetUserMedia ||
 	        navigator.msGetUserMedia);
 			navigator.getMedia({video: true},()=>{
				
				axios
			        .post('/startexamcategorywise/mainexambycategory/'+compId+'/'+studentId)
			        .then((response)=>{						
						// this.props.history.push('/startExam/'+compId+'/'+response.data.ID);
						// this.props.history.push('/startExam/'+compId+'/'+response.data.record._id);
						var MCQdata = response.data.record;
						// console.log("MCQdata", MCQdata);
						self.setState({MCQdata: MCQdata});
			        })
			        .catch(function(error){			          	
			          	$('.startExamBtn').css('display','Block');
						$('.wrProcessing').css('display','none');
			        })
			  


 			}, function() {
 				$('.startExamBtn').css('display','Block');
 				$('.wrProcessing').css('display','none');
 			    swal({title : "As per company's rule, Student will be not allowed to attempt the final exam without camera",
 			    		text: "",
 			    		icon: "warning"})
		 			    .then((will) => {
		           window.location.reload(false);
		        });
 			     
 			});*/
		
		
	}

	gotoPreviousMainExam(event){
// 		var id = $(event.target).attr('id');
// 		navigator.getMedia = ( 
// 		// navigator.getUserMedia || // use the proper vendor prefix
//         navigator.webkitGetUserMedia ||
//         navigator.mozGetUserMedia ||
//         navigator.msGetUserMedia);
// 		navigator.getMedia({video: true}, function() {
// 		  }, function() {
// 		  	// FlowRouter.go('/iAgreeAndStartExam');
// 		    swal("As per our rule, you will be not allowed to attempt exam without camera","","warning");
// 		});
		// FlowRouter.go("/startExam/"+id);
	}

	MainExamComplete(event){
		var id = $(event.target).attr('id');
		swal({
			  title              : 'Are you sure?',
			  text               : 'You will not be able to attempt this exam!',
			  type               : 'warning',
			  showCancelButton   : true,
			  confirmButtonColor : '#dd6b55',
			  cancelButtonColor  : '#d44',
			  confirmButtonText  : 'Yes',
			  cancelButtonText   : 'No',
			  closeOnConfirm     : false
			}, function() {
			

		});
	}
	// this function is assuming due to bab internet or internet is not available this function will execute
	
	
	
	render(){
		
		$('.sidebar').css({display:'block',background: '#222d32'});
		if(this.state.MCQdata){
		      return(
		        <MCQ questions={this.state.MCQdata} examType="mainExam" />
		      )
		}
    	else{
			
			if(this.state.webcamera){

			return(
				
			<div>				
			    <div className="content-wrapper">
			          {/* Content Header (Page header) */}
			          <section className="content-header">			           
			            	<h1>Start Main Exam </h1>			            	
			          </section>
			          
			          <section className="content viewContent">
			            <div className="row">
			              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
			                <div className="box">
			            	
			            		<div className="box-header with-border boxMinHeight">
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 webcamboxtop">
										
										<div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 ">
											<Webcam className="videoSizeDisplay pull-right webcamepagewide" autoPlay="true"
								              audio={false}
								              height={500}
								              width={500} 
								              ref={node => this.webcam = node}
								            />
										</div>
										<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 webcaminstructionright">
											<h2 className="headingwebcam">Your camera is looking fine to me</h2>
											<p className="parawebcam">Please make sure that no one else is accompanying you while solving the exam. No one should be standing behind you or sitting next to you.</p>
											<p className="parawebcam">if you are ready, please click the below button to start the exam.</p>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 btnboxouter">
												<button type="submit" className="btn btn-success startExamBtn startexamonwebcambtn" onClick={this.startExam.bind(this)}>Start Exam </button>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 btnboxouter">
												<button type="button" className="btn btn-success wrProcessing startexamonwebcambtn">We are processing... </button>
											</div>
										</div>
																				
									</div>
								</div>

							</div>
						  </div>
						</div>
					  </section>
					</div>
				</div>
				);
			}
				else{
					return(
						<div>				
						    <div className="content-wrapper">
						          {/* Content Header (Page header) */}
						          <section className="content-header">			           
						            	<h1>Start Main Exam </h1>			            	
						          </section>
						          
						          <section className="content viewContent">
						            <div className="row">
						              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
						                <div className="box">
						            	
						            		<div className="box-header with-border boxMinHeight">
												<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 webcamboxtop">
													
													<div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 webcamoffsection">
														<p className="offcameratext">Camera is off</p>
													</div>
													<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 webcaminstructionright">
														<h2 className="headingwebcam">Your camera is not working fine.</h2>
														<p className="parawebcam">Please make sure your camera is working and then only we can proceed for the exam.</p>
														
													</div>
																							
												</div>
											</div>

										</div>
									  </div>
									</div>
								  </section>
								</div>
							</div>
						);
				}
			}
		}
}
export default Webcampage;
